<template >
    <div>
        <div class="app-modal__box">
            <div class="app-modal__in">
                <div
                    class="app-modal__header d-flex f-between p-5 m-title-modal"
                >
                    <p class="large--title m-0">Услуги</p>
                    <div>
                        <crm-store-update-close
                            :permission="$options.name"
                            :button_type="'store'"
                            :loading="loadingButton"
                            @c-submit="submit(true)"
                            @c-close="close()"
                        ></crm-store-update-close>
                    </div>
                </div>
            </div>
            <!-- app-modal__header end -->

            <div class="app-modal__body p-5 pb-0">
                <div class="timeline-items__right rounded-sm w-100 p-4">
                    <el-form
                        ref="form"
                        status-icon
                        :model="form"
                        :rules="rules"
                    >
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <div class="app-form__group mb-4">
                                    <el-form-item
                                        prop="name"
                                        size="mini"
                                        label="Ф.И.О."
                                    >
                                        <el-input
                                            placeholder="Ф.И.О."
                                            v-model="form.name"
                                            size="medium"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="app-form__group mb-4">
                                    <el-form-item
                                        prop="name"
                                        size="mini"
                                        label="Код"
                                    >
                                        <el-input
                                            placeholder="Код"
                                            v-model="form.name"
                                            size="medium"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="app-form__group mb-4">
                                    <el-form-item
                                        prop="name"
                                        size="mini"
                                        label="Эл. адрес"
                                    >
                                        <el-input
                                            placeholder="Эл. адрес"
                                            v-model="form.name"
                                            size="medium"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="app-form__group mb-4">
                                    <el-form-item
                                        prop="name"
                                        size="mini"
                                        label="Телефон"
                                    >
                                        <el-input
                                            placeholder="Телефон"
                                            v-model="form.name"
                                            size="medium"
                                        ></el-input>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <!-- end col -->

                            <el-col :span="12">
                                <div class="app-form__group mb-4">
                                    <el-form-item
                                        prop="slug"
                                        size="mini"
                                        label="Фактический адрес"
                                    >
                                        <el-input
                                            placeholder="Фактический адрес"
                                            v-model="form.slug"
                                            size="medium"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="app-form__group mb-4">
                                    <el-form-item
                                        prop="slug"
                                        size="mini"
                                        label="ИНН"
                                    >
                                        <el-input
                                            placeholder="ИНН"
                                            v-model="form.slug"
                                            size="medium"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="app-form__group mb-4">
                                    <el-form-item
                                        prop="slug"
                                        size="mini"
                                        label="Навыки"
                                    >
                                        <el-input
                                            type="textarea"
                                            v-model="form.desc"
                                        ></el-input>
                                    </el-form-item>
                                </div>

                                <div class="app-form__group mb-4">
                                    <el-form-item
                                        prop="slug"
                                        size="mini"
                                        label="Комментарий"
                                    >
                                        <el-input
                                            type="textarea"
                                            v-model="form.desc"
                                        ></el-input>
                                    </el-form-item>
                                </div>
                            </el-col>

                            <!-- end col -->
                            <el-col :span="24">
                                <el-tabs
                                    v-model="activeName"
                                    @tab-click="handleClick"
                                >
                                    <el-tab-pane
                                        label="Виды предоставляемых услуг"
                                        name="first"
                                    >
                                        <el-row>
                                            <el-col :span="18">
                                                <div
                                                    class="
                                                        app-form__group
                                                        mb-4
                                                        ikklik
                                                    "
                                                >
                                                    <el-form-item
                                                        prop="slug"
                                                        size="mini"
                                                        label="Виды предоставляемых услуг"
                                                    >
                                                        <el-select
                                                            class="w-100"
                                                            size="medium"
                                                            v-model="
                                                                form.region
                                                            "
                                                            filterable
                                                            placeholder="Виды предоставляемых услуг"
                                                        >
                                                            <el-option
                                                                label="Zone one"
                                                                value="shanghai"
                                                            ></el-option>
                                                            <el-option
                                                                label="Zone two"
                                                                value="beijing"
                                                            ></el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                    <el-button
                                                        size="small"
                                                        icon="el-icon-plus"
                                                    >
                                                    </el-button>
                                                </div>
                                            </el-col>

                                            <el-col :span="24">
                                                <el-table
                                                    :data="tableData"
                                                    border
                                                    style="width: 100%"
                                                >
                                                    <el-table-column
                                                        prop="name"
                                                        label="Наименование"
                                                    >
                                                    </el-table-column>
                                                    <el-table-column
                                                        prop="namefull"
                                                        label="Услуги полного имени"
                                                    >
                                                    </el-table-column>
                                                    <el-table-column
                                                        prop="cod"
                                                        label="Код"
                                                    >
                                                    </el-table-column>
                                                    <el-table-column
                                                        prop="actione"
                                                        label="Статус"
                                                        width="180"
                                                    >
                                                        <el-switch
                                                            v-model="value2"
                                                            active-color="#13ce66"
                                                            inactive-color="#ff4949"
                                                        >
                                                        </el-switch>
                                                        <el-button
                                                            class="ml-3"
                                                            size="mini"
                                                            type="danger"
                                                            icon="el-icon-delete"
                                                            circle
                                                        ></el-button>
                                                    </el-table-column>
                                                </el-table>
                                            </el-col>
                                        </el-row>
                                    </el-tab-pane>

                                    <el-tab-pane
                                        label="Добавит Кампания"
                                        name="second"
                                    >
                                        <el-row>
                                            <el-col :span="18">
                                                <div
                                                    class="
                                                        app-form__group
                                                        mb-4
                                                        ikklik
                                                    "
                                                >
                                                    <el-form-item
                                                        prop="slug"
                                                        size="mini"
                                                        label="Добавит Кампания"
                                                    >
                                                        <el-select
                                                            class="w-100"
                                                            size="medium"
                                                            v-model="
                                                                form.region
                                                            "
                                                            filterable
                                                            placeholder=" Добавить мастера"
                                                        >
                                                            <el-option
                                                                label="Zone one"
                                                                value="shanghai"
                                                            ></el-option>
                                                            <el-option
                                                                label="Zone two"
                                                                value="beijing"
                                                            ></el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                    <el-button
                                                        size="small"
                                                        icon="el-icon-plus"
                                                    >
                                                    </el-button>
                                                </div>
                                            </el-col>

                                            <el-col :span="24">
                                                <el-table
                                                    :data="tableDataMaster"
                                                    border
                                                    style="width: 100%"
                                                >
                                                    <el-table-column
                                                        prop="name"
                                                        label="Наименование"
                                                    >
                                                    </el-table-column>
                                                    <el-table-column
                                                        prop="skillsKompani"
                                                        label="Услуги полного имени"
                                                    >
                                                    </el-table-column>
                                                    <el-table-column
                                                        prop="telephone"
                                                        label="Телефон"
                                                    >
                                                    </el-table-column>
                                                    <el-table-column
                                                        prop="cod"
                                                        label="Код"
                                                    >
                                                    </el-table-column>
                                                    <el-table-column
                                                        prop="actione"
                                                        label="Статус"
                                                        width="180"
                                                    >
                                                        <el-switch
                                                            v-model="value2"
                                                            active-color="#13ce66"
                                                            inactive-color="#ff4949"
                                                        >
                                                        </el-switch>
                                                        <el-button
                                                            class="ml-3"
                                                            size="mini"
                                                            type="danger"
                                                            icon="el-icon-delete"
                                                            circle
                                                        ></el-button>
                                                    </el-table-column>
                                                </el-table>
                                            </el-col>
                                        </el-row>
                                    </el-tab-pane>
                                </el-tabs>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </div>
            <!-- end app-modal__body -->
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            activeName: "first",
            form: {},
            tableData: [
                {
                    name: "Sozlash",
                    namefull: "to'liq o'rnatib berish",
                    cod: "No. 189,",
                    actione: "",
                },
                {
                    name: "Sozlash",
                    namefull: "to'liq o'rnatib berish",
                    cod: "No. 189,",
                    actione: "",
                },
                {
                    name: "Sozlash",
                    namefull: "to'liq o'rnatib berish",
                    cod: "No. 189,",
                    actione: "",
                },
                {
                    name: "Sozlash",
                    namefull: "to'liq o'rnatib berish",
                    cod: "No. 189,",
                    actione: "",
                },
            ],
            tableDataMaster: [
                {
                    name: "Alisher Orinov",
                    skillsKompani:
                        " Монтаж кондиционера, Монтаж ,   Монтаж стиральной ма",
                    telephone: "+998 90 200 20 20",
                    cod: "No. 189,",
                    actione: "",
                },
                {
                    name: "Alisher Orinov",
                    skillsKompani:
                        " Монтаж кондиционера, Монтаж ,   Монтаж стиральной ма",
                    telephone: "+998 90 200 20 20",
                    cod: "No. 189,",
                    actione: "",
                },
            ],
        };
    },
};
</script>
