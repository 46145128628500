<template>
    <div class="texno-khan-o">
        <div class="card-table-body ifon-add-title-block">
            <el-row :gutter="20">
                <el-col :span="12">
                    <div class="caz-blocks-sarcho-title">
                        <div
                            class="content-title d-flex align-center mr-2"
                            :class="
                                mode
                                    ? 'content__titleday'
                                    : 'content__titlenight'
                            "
                        >
                            Мастера
                        </div>
                        <div class="block-sarche">
                            <div class="header__search d-flex">
                                <crm-input
                                    :size="'small'"
                                    :className="'w100'"
                                    :class="
                                        mode ? 'input__day' : 'input__night'
                                    "
                                    v-model="search"
                                    :icon="'el-icon-search'"
                                    class="mr-3"
                                    style="width: 220px"
                                ></crm-input>
                                <el-date-picker
                                    v-model="value1"
                                    :class="
                                        mode ? 'input__day' : 'input__night'
                                    "
                                    size="small"
                                    type="date"
                                    class="mr-3"
                                    placeholder="От"
                                >
                                </el-date-picker>
                                <el-date-picker
                                    v-model="value1"
                                    size="small"
                                    :class="
                                        mode ? 'input__day' : 'input__night'
                                    "
                                    type="date"
                                    placeholder="До"
                                >
                                </el-date-picker>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" class="flex-style text-right">
                    <crm-create-and-column-settings
                        :permission="$options.name"
                        @c-create="drawerCreate = true"
                        :class="
                            mode
                                ? 'button__settingsday'
                                : 'button__settingsnight'
                        "
                        :columns="columns"
                        @c-change="updateColumn"
                    >
                    </crm-create-and-column-settings>
                </el-col>
            </el-row>
        </div>
        <!-- end ifon-add-title-block -->

        <div class="card-table-header table-crm-smart mt-0 pt-0">
            <table
                class="table-my-code table-bordered mt-0 pt-0"
                :class="mode ? 'table__myday' : 'table__mynight'"
                v-loading="loadingData"
            >
                <thead>
                    <tr>
                        <th class="w50p" v-if="columns.id.show">
                            {{ columns.id.title }}
                        </th>

                        <th v-if="columns.napeProduct.show">
                            {{ columns.napeProduct.title }}
                        </th>

                        <th v-if="columns.userSystem.show">
                            {{ columns.userSystem.title }}
                        </th>

                        <th v-if="columns.servisName.show">
                            {{ columns.servisName.title }}
                        </th>

                        <th v-if="columns.numberMasters.show">
                            {{ columns.numberMasters.title }}
                        </th>

                        <th v-if="columns.dataContract.show">
                            {{ columns.dataContract.title }}
                        </th>

                        <th v-if="columns.data.show">
                            {{ columns.data.title }}
                        </th>

                        <th v-if="columns.settings.show">
                            {{ columns.settings.title }}
                        </th>
                    </tr>

                    <tr class="filter_sorche">
                        <th v-if="columns.id.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.id"
                                :placeholder="columns.id.title"
                                class="id_input"
                            ></el-input>
                        </th>

                        <th v-if="columns.napeProduct.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.napeProduct"
                                :placeholder="columns.napeProduct.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.userSystem.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.userSystem"
                                :placeholder="columns.userSystem.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.servisName.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.servisName"
                                :placeholder="columns.servisName.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.numberMasters.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.numberMasters"
                                :placeholder="columns.numberMasters.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.dataContract.show">
                            <el-date-picker
                                :class="mode ? 'mode_1' : 'mode__2'"
                                :placeholder="columns.dataContract.title"
                                v-model="filterForm.dataContract"
                                size="mini"
                            >
                            </el-date-picker>
                        </th>

                        <th v-if="columns.data.show">
                            <el-date-picker
                                :class="mode ? 'mode_1' : 'mode__2'"
                                :placeholder="columns.data.title"
                                v-model="filterForm.data"
                                size="mini"
                            >
                            </el-date-picker>
                        </th>

                        <th class="settinW" v-if="columns.settings.show"></th>
                    </tr>
                </thead>

                <transition-group name="flip-list" tag="tbody">
                    <tr
                        v-for="user in list"
                        :key="user.id"
                        class="cursor-pointer"
                    >
                        <td v-if="columns.id.show">{{ user.id }}</td>

                        <td v-if="columns.napeProduct.show">
                            {{ user.napeProduct }}
                        </td>

                        <td v-if="columns.userSystem.show">
                            {{ user.userSystem }}
                        </td>

                        <td v-if="columns.servisName.show">
                            {{ user.servisName }}
                            <el-button
                                type="success"
                                icon="el-icon-view"
                                circle
                                size="mini"
                                @click="drawerCompany = true"
                            ></el-button>
                        </td>

                        <td v-if="columns.numberMasters.show">
                            {{ user.numberMasters }}
                            <el-button
                                type="success"
                                icon="el-icon-view"
                                circle
                                size="mini"
                                @click="drawerMaster = true"
                            ></el-button>
                        </td>

                        <td v-if="columns.dataContract.show">
                            {{ user.dataContract }}
                        </td>

                        <td v-if="columns.data.show">{{ user.data }}</td>

                        <td v-if="columns.settings.show" class="settings-td">
                            <el-dropdown
                                :hide-on-click="false"
                                class="table-bottons-right"
                            >
                                <span class="el-dropdown-link more_icons">
                                    <i
                                        class="
                                            el-icon-arrow-down
                                            el-icon-more-outline
                                        "
                                    ></i>
                                </span>
                                <el-dropdown-menu
                                    slot="dropdown"
                                    class="dropdown-menumy-style"
                                >
                                    <el-dropdown-item icon="el-icon-edit">
                                        Изменить
                                    </el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-delete">
                                        Удалить
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </td>
                    </tr>
                </transition-group>
            </table>
        </div>

        <div class="app-modal app-modal__full modal-color-bg">
            <el-drawer
                :with-header="false"
                :visible.sync="drawerCreate"
                ref="drawerCreate"
                size="70%"
            >
                <div>
                    <crm-create> </crm-create>
                </div>
            </el-drawer>

            <el-drawer
                :with-header="false"
                :visible.sync="drawerMaster"
                size="70%"
            >
                <div>
                    <CrmMaster> </CrmMaster>
                </div>
            </el-drawer>

            <el-drawer
                :with-header="false"
                :visible.sync="drawerCompany"
                size="70%"
            >
                <div>
                    <CrmCompany> </CrmCompany>
                </div>
            </el-drawer>
        </div>
    </div>
</template>
<script>
import CrmCreate from "./components/crm-create";
import CrmMaster from "./components/crm-master.vue";
import CrmCompany from "./components/crm-company";
export default {
    components: {
        CrmCreate,
        CrmMaster,
        CrmCompany,
    },
    name: "companyTexno",
    data() {
        return {
            drawerCreate: false,
            drawerMaster: false,
            drawerCompany: false,
            filterForm: {
                id: "",
                napeProduct: "",
                userSystem: "",
                servisName: "",
                numberMasters: "",
                dataContract: "",
                data: "",
            },
            list: [
                {
                    id: "8",
                    napeProduct: "Samsung RB30N4020B1/WT",
                    userSystem: "Холодильник",
                    servisName: "10",
                    numberMasters: "10",
                    dataContract: "02.05.1995",
                    data: "12.12.2020",
                },
            ],
            columns: {
                id: {
                    show: true,
                    title: "№",
                    sortable: true,
                    column: "id",
                },

                napeProduct: {
                    show: true,
                    title: "Ф.И.О.",
                    sortable: true,
                    column: "napeProduct",
                },

                userSystem: {
                    show: true,
                    title: "Пользователь",
                    sortable: true,
                    column: "userSystem",
                },

                servisName: {
                    show: true,
                    title: "Виды предоставляемых услуг",
                    sortable: true,
                    column: "servisName",
                },

                numberMasters: {
                    show: true,
                    title: "Количество Кампания",
                    sortable: true,
                    column: "numberMasters",
                },

                dataContract: {
                    show: true,
                    title: "Дата создания ",
                    sortable: true,
                    column: "dataContract",
                },
                data: {
                    show: true,
                    title: "Изменено ",
                    sortable: true,
                    column: "data",
                },
                settings: {
                    show: true,
                    title: "Профиль",
                    sortable: false,
                    column: "settings",
                },
            },
        };
    },
};
</script>
